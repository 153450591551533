/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  getAllNfts,
  getAllFreds,
  getUserMarketplaceNfts,
  getUserMarketplaceTvtsTokens,
  getAllTvtsForClients,
  getAllUserMarketplaceFreds,
  getAllUserMarketplaceSFreds,
  getAllTvts,
  getAllSuperFreds,
} from "../services/NftServices";
import { useLocation } from "react-router-dom";
import NftCard from "../components/Nft/NftCard";
import FredCard from "../components/Fred/FredCard";
import Loader from "../components/loader/loader";
import SuperFredCard from "../components/SuperFred/SuperFredCard";
import NoData from "../components/NoDataComp/NoData";
import NoDataImage from "../images/NoDataImage.png";
import SellNftCard from "../components/Nft/SellNftCard";
import SellFredCard from "../components/Fred/SellFredCard";
import SelltvtCard from "../components/Tvt/SellTvtCard";
import SellSFREDCard from "../components/SuperFred/SellSFREDCard";
import { ethers } from "ethers";
import TvtCard from "../components/Tvt/TvtCard";
import { ConversionContext } from "../Context/context";
import TvtAbi from "../Abis/TvtAbi.json";
import FredAbi from "../Abis/FredAbi.json";
import SfredAbi from "../Abis/SfredAbi.json";
import SRAbi from "../Abis/SR_ABi.json";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import configURl from "../runtime.config";
import SellSrCard from "../components/SolosReward/SellSrCard";

const Marketplace = () => {
  const location = useLocation();
  const signer = useSigner();
  const activeKey = location?.state?.data;
  const [nftData, setNftData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fredData, setFredData] = useState([]);
  const [tvtData, setTvtData] = useState([]);
  const [superFredData, setSuperFredData] = useState([]);
  const [userSuperFredData, setUserSuperFredData] = useState([]);
  const [userMarketplaceNfts, setUserMarketplaceNfts] = useState([]);
  const [userMarketplaceTvts, setUserMarketplaceTvts] = useState([]);
  const [userMarketplaceSRs, setUserMarketplaceSRs] = useState([]);
  const [userMarketplaceFredNfts, setUserMarketplaceFredNfts] = useState([]);
  const [filter, setFilter] = useState("all");
  const contextData = useContext(ConversionContext);
  const adminReceiveWalletAddress = contextData?.adminReceiveWalletAddress;
  const UserId = contextData?.userId;
  const MyAddress = useAddress();
  const activeTab = activeKey ? activeKey : "solosmarket";
  const fetchNfts = async (pageNumber = 1, pageSize = 4, search = "") => {
    try {
      setLoading(true);
      const response = await getAllNfts(pageNumber, pageSize, search);
      if (response.status === 200) {
        setNftData(response.data.items);
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  const fetchFreds = async (pageNumber = 1, pageSize = 4, search = "") => {
    try {
      setLoading(true);
      const response = await getAllFreds(pageNumber, pageSize, search);
      if (response.status === 200) {
        setLoading(false);
        console.log(response, "response?.data?.data");
        setFredData(response?.data?.filteredData);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const fetchTvts = async (pageNumber = 1, pageSize = 4, search = "") => {
    try {
      setLoading(true);
      const response = await getAllTvts(pageNumber, pageSize, search);
      if (response.status === 200) {
        setLoading(false);
        // setTvtData(response.data?.filteredData);

        const allTvtData = response.data?.filteredData.filter((tvt) => tvt?.quantityForSellInSoloMarketPlace > 0);
        const allTvtDataWithoutTest = response?.data?.filteredData?.filter(
          (tvt) => !tvt?.["name"]?.toLowerCase()?.includes("test")
        );
        setTvtData(
          // window.location.hostname.includes("localhost") ?
          allTvtData
          // : allTvtDataWithoutTest
        );
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  const fetchSuperFreds = async (pageNumber = 1, pageSize = 4, search = "") => {
    try {
      setLoading(true);
      const response = await getAllSuperFreds(pageNumber, pageSize, search);
      if (response.status === 200) {
        setLoading(false);
        setSuperFredData(response?.data?.filteredData);
        // console.log(response, "market place response of s fred .....");
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const fetchUserSuperFreds = async () => {
    try {
      setLoading(true);
      const response = await getAllUserMarketplaceSFreds();
      if (response.status === 200) {
        setLoading(false);
        const allSFredData = response.data.items;
        // console.log(allSFredData, "allSFredData");
        let result = [];
        for (let index = 0; index < allSFredData.length; index++) {
          const element = allSFredData[index];
          let res = await getMarketplaceSFreds(element?.contractAddress);

          // console.log(res, "response12121212");
          if (res) {
            if (res.length > 0) {
              for (let index = 0; index < res.length; index++) {
                const sfredDetail = res[index];
                if (sfredDetail?.SfredCount != 0) {
                  result.push({
                    ...element,
                    balance: sfredDetail?.SfredPrice.toString(),
                    quantity: sfredDetail?.SfredCount.toString(),
                    address: sfredDetail?.userAddress,
                  });
                }
              }
            }
          }
        }
        console.log(result, "response12121212");
        setUserSuperFredData(result);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching super fred data:", error);
    }
  };

  const fetchUserMarketplaceNfts = async (
    pageNumber = 1,
    pageSize = 4,
    search = ""
  ) => {
    try {
      setLoading(true);
      const response = await getUserMarketplaceNfts(
        pageNumber,
        pageSize,
        search
      );
      if (response.status === 200) {
        setLoading(false);
        setUserMarketplaceNfts(response?.data?.items);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const fetchUserMarketplaceTvts = async () => {
    try {
      setLoading(true);
      const response = await getAllTvtsForClients();

      if (response.status === 200) {
        const allTvtData = response?.data?.items;
        // window.location.hostname.includes("localhost")
        //   ? 
        // response?.data?.items
        // : response?.data?.items?.filter(
        //   (tvt) => !tvt?.["categories"]?.toLowerCase()?.includes("test")
        // );
        let result = [];
        for (let index = 0; index < allTvtData.length; index++) {
          const element = allTvtData[index];
          let res = await getMarketplaceTvts(element?.contractAddress);
          if (res) {
            if (res.length > 0) {
              for (let index = 0; index < res.length; index++) {
                const tvtDetail = res[index];
                if (tvtDetail?.tvtCount != 0 && element?.isNewTvt) {
                  const intBalance = element?.isPurchasable ? ethers.utils.formatEther(tvtDetail?.tvtPrice.toString()) : tvtDetail?.tvtPrice.toString();
                  result.push({
                    ...element,
                    balance: intBalance,
                    quantity: tvtDetail?.tvtCount.toString(),
                    address: tvtDetail?.userAddress,
                  });
                }
              }
            }
          }
        }
        setUserMarketplaceTvts(result);
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
    // try {
    //   setLoading(true);
    //   const response = await getUserMarketplaceTvtsTokens();
    //   if (response.status === 200) {
    //     setLoading(false);
    //     setUserMarketplaceTvts(response?.data);
    //   } else {
    //     throw new Error(response.error);
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.error("Error fetching nft data:", error);
    // }
  };
  const getMarketplaceTvts = async (data) => {
    const nftContract = new ethers.Contract(data, TvtAbi, signer);
    let respones = await nftContract?.getAllUserData();
    return respones;
  };
  const getMarketplaceFreds = async (data) => {
    const fredContract = new ethers.Contract(data, FredAbi, signer);
    let respones = await fredContract?.getAllUserData();
    return respones;
  };
  const getMarketplaceSR = async (address) => {
    const srContract = new ethers.Contract(address, SRAbi, signer);
    let respones = await srContract?.getAllUserData();
    return respones;
  };
  const getMarketplaceSFreds = async (data) => {
    const sfredContract = new ethers.Contract(data, SfredAbi, signer);
    let respones = await sfredContract?.getAllUserData();
    return respones;
  };
  const fetchUserMarketplaceFredNfts = async () => {
    try {
      setLoading(true);
      const response = await getAllUserMarketplaceFreds();
      if (response.status === 200) {
        setLoading(false);
        const allFredData = response?.data?.items;
        console.log(allFredData, "allFredData");
        let result = [];
        for (let index = 0; index < allFredData.length; index++) {
          const element = allFredData[index];
          let res = await getMarketplaceFreds(element?.contractAddress);
          if (res) {
            if (res.length > 0) {
              for (let index = 0; index < res.length; index++) {
                const fredDetail = res[index];
                if (fredDetail?.fredCount != 0) {
                  result.push({
                    ...element,
                    balance: fredDetail?.fredPrice.toString(),
                    quantity: fredDetail?.fredCount.toString(),
                    address: fredDetail?.userAddress,
                  });
                }
              }
            }
          }
        }
        setUserMarketplaceFredNfts(result);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const fetchUserMarketplaceSR = async () => {
    try {
      setLoading(true);
      let result = [];
      let res = await getMarketplaceSR(configURl.SR_Address);
      console.log(res, "getMarketplaceSR result");
      if (res) {
        if (res.length > 0) {
          for (let index = 0; index < res.length; index++) {
            const SrDetail = res[index];
            if (SrDetail?.SRCount != 0) {
              result.push({
                name: "Solos Rewards",
                symbol: "SR",
                contractAddress: configURl.SR_Address,
                image:
                  "https://solo-s3-bucket.s3.amazonaws.com/jz7hue1agwlogo_Reward.jpg",
                balance: SrDetail?.SRPrice.toString(),
                quantity: SrDetail?.SRCount.toString(),
                address: SrDetail?.userAddress,
              });
            }
          }
        }
      }
      setUserMarketplaceSRs(result);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching SR :", error);
    }
  };

  useEffect(() => {
    fetchNfts();
    fetchFreds();
    fetchTvts();
    fetchUserMarketplaceNfts();
    fetchUserMarketplaceFredNfts();
    fetchSuperFreds();
    fetchUserMarketplaceTvts();
    fetchUserSuperFreds();
    fetchUserMarketplaceSR();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MyAddress]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="ps-lg-4 ps-3">
        <div className="dashboard-centerd">
          <div className="row g-2" style={{ width: "100%" }}>
            <div className="col-xl-12 col-xxl-12 pe-lg-4 pe-3 pt-4 border-end center-height pb-5">
              <div className="">
                <Tabs
                  defaultActiveKey={activeTab}
                  id="marketplace_tabs"
                  className="marketplace-tabs border-0 mb-4"
                >
                  <Tab eventKey="usermarket" title="User Market">
                    {/* <div className="mt-5" style={{alignItems:'center',justifyContent:'center',display:'flex'}}> */}
                    {/* <img src={NoDataImage} style={{height:'50vh'}} /></div> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "25px 0px",
                      }}
                    >
                      <div style={{ color: "black" }}></div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div>
                          <select
                            className="customSelect"
                            aria-label="Default select example"
                            onChange={(e) => setFilter(e.target.value)}
                          >
                            <option selected value="all">
                              All
                            </option>
                            <option value="nfts">Possessions</option>
                            <option value="freds">FREDs</option>
                            <option value="tvts">TVTs</option>
                            <option value="sfreds">Super FRED</option>
                          </select>
                        </div>
                        {/* <div>
                          <select
                            className="customSelect"
                            aria-label="Default select example"
                          >
                            <option selected>Filter by prices</option>
                            <option value="1">Price low to high</option>
                            <option value="2">Price high to low</option>
                            <option value="3">All</option>
                          </select>
                        </div> */}
                      </div>
                    </div>
                    {filter === "all" || filter === "sr" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                          <h6 className="fw-600 text-black">SR</h6>
                          {userMarketplaceSRs?.length > 0 && (
                            <Link
                              className="view-btn"
                              to="/all-user-marketplace-freds"
                            >
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {userMarketplaceSRs?.length > 0 ? (
                          userMarketplaceSRs?.slice(0, 4)?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                              >
                                <SellSrCard
                                  data={item}
                                  UserId={UserId}
                                  setLoading={setLoading}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {filter === "all" || filter === "tvts" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                          <h6 className="fw-600 text-black">TVTs</h6>
                          {userMarketplaceTvts?.length > 0 && (
                            <Link
                              className="view-btn"
                              to="/all-user-marketplace-tvts"
                            >
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {/* <NoData /> */}
                        {userMarketplaceTvts?.length > 0 ? (
                          userMarketplaceTvts?.slice(0, 4)?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="col-xxl-3 col-xl-4 col-lg-6 col-12 mb-4 card-cols"
                              >
                                <SelltvtCard
                                  data={item}
                                  fetchData={fetchUserMarketplaceTvts}
                                  setLoading={setLoading}
                                  UserId={UserId}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {filter === "all" || filter === "freds" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                          <h6 className="fw-600 text-black">FREDs</h6>
                          {userMarketplaceFredNfts?.length > 0 && (
                            <Link
                              className="view-btn"
                              to="/all-user-marketplace-freds"
                            >
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {userMarketplaceFredNfts?.length > 0 ? (
                          userMarketplaceFredNfts
                            ?.slice(0, 4)
                            ?.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                                >
                                  <SellFredCard
                                    data={item}
                                    UserId={UserId}
                                    fetchData={fetchUserMarketplaceFredNfts}
                                    setLoading={setLoading}
                                  />
                                </div>
                              );
                            })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {filter === "all" || filter === "sfreds" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                          <h6 className="fw-600 text-black">SFREDs</h6>
                          {userSuperFredData?.length > 0 && (
                            <Link
                              className="view-btn"
                              to="/all-user-marketplace-sfreds"
                            >
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {userSuperFredData?.length > 0 ? (
                          userSuperFredData?.slice(0, 4).map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                              >
                                <SellSFREDCard
                                  UserId={UserId}
                                  data={item}
                                  // fetchData={fetchUserMarketplaceFredNfts}
                                  setLoading={setLoading}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {filter === "all" || filter === "nfts" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                          <h6 className="fw-600 text-black">Possessions</h6>
                          {userMarketplaceNfts?.length > 0 && (
                            <Link
                              className="view-btn"
                              to="/all-user-marketplace-nfts"
                            >
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {userMarketplaceNfts?.length > 0 ? (
                          userMarketplaceNfts?.slice(0, 4).map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                              >
                                <SellNftCard
                                  data={item}
                                  fetchData={fetchUserMarketplaceNfts}
                                  setLoading={setLoading}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <div className="row border-bottom mb-4">
                <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                  <h6 className="fw-600 text-black">User Market</h6>
                  <Link className="view-btn" to="/">
                    view all <BiChevronRight />
                  </Link>
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                  <Possession />
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                  <Possession />
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                  <Possession />
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4">
                  <Possession />
                </div>
              </div> */}
                  </Tab>
                  <Tab eventKey="solosmarket" title="Solos Market">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "25px 0px",
                      }}
                    >
                      <div style={{ color: "black" }}></div>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "10px" }}>
                          <select
                            className="customSelect"
                            aria-label="Default select example"
                            onChange={(e) => setFilter(e.target.value)}
                          >
                            <option selected value="all">
                              All
                            </option>
                            <option value="nfts">Possessions</option>
                            <option value="freds">FREDs</option>
                            <option value="tvts">TVTs</option>
                            <option value="superFreds">SUPER FREDs</option>
                          </select>
                        </div>
                        {/* <div>
                          <select
                            className="customSelect"
                            aria-label="Default select example"
                          >
                            <option selected>Filter by prices</option>
                            <option value="1">Price low to high</option>
                            <option value="2">Price high to low</option>
                            <option value="3">All</option>
                          </select>
                        </div> */}
                      </div>
                    </div>
                    {filter === "all" || filter === "tvts" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                          <h6 className="fw-600 text-black">Trending TVTs</h6>
                          {tvtData.length > 0 && (
                            <Link className="view-btn" to="/all-tvts">
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {tvtData.length > 0 ? (
                          tvtData?.slice(0, 4)?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="col-xxl-3 col-xl-4 col-lg-6 col-12 mb-4 card-cols"
                              >
                                <TvtCard
                                  data={item}
                                  fetchData={fetchTvts}
                                  setLoading={setLoading}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {filter === "all" || filter === "freds" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                          <h6 className="fw-600 text-black">Trending FREDs</h6>
                          {fredData?.length > 0 && (
                            <Link className="view-btn" to="/all-freds">
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {fredData?.length > 0 ? (
                          fredData?.slice(0, 4)?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                              >
                                <FredCard
                                  data={item}
                                  fetchData={fetchFreds}
                                  setLoading={setLoading}
                                  // adminPrivateKey={adminPrivateKey}
                                  adminReceiveWalletAddress={
                                    adminReceiveWalletAddress
                                  }
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {filter === "all" || filter === "superFreds" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                          <h6 className="fw-600 text-black">
                            Trending Super FREDs
                          </h6>
                          {superFredData.length > 0 && (
                            <Link className="view-btn" to="/all-sfreds">
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {superFredData.length > 0 ? (
                          superFredData?.slice(0, 4).map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                              >
                                <SuperFredCard
                                  data={item}
                                  fetchData={fetchSuperFreds}
                                  setLoading={setLoading}
                                  adminReceiveWalletAddress={
                                    adminReceiveWalletAddress
                                  }
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {filter === "all" || filter === "nfts" ? (
                      <div className="row border-bottom mb-4 g-2">
                        <div className="col-12 d-flex align-items-center justify-content-between mb-2 ">
                          <h6 className="fw-600 text-black">
                            Trending Possessions
                          </h6>
                          {nftData?.length > 0 && (
                            <Link className="view-btn" to="/all-nfts">
                              View All <BiChevronRight />
                            </Link>
                          )}
                        </div>
                        {nftData?.length > 0 ? (
                          nftData?.slice(0, 4).map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                              >
                                <NftCard
                                  data={item}
                                  fetchData={fetchNfts}
                                  setLoading={setLoading}
                                  // adminPrivateKey={adminPrivateKey}
                                  adminReceiveWalletAddress={
                                    adminReceiveWalletAddress
                                  }
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NoData />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
