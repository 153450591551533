import "./App.css";
import { useRoutes } from "react-router-dom";
import { UseRoute } from "./routes/route";
import { apiHeader } from "./helpers/api_helper";
import { onMessageListener } from "./firebase/firebase";
import { useEffect } from "react";
import { getMyInfoAxios, UpdateProfile } from "./services/propertyServices";
import {
  EmbeddedWallet,
  embeddedWallet,
  useAddress,
  useConnect,
} from "@thirdweb-dev/react";
import variables from "./Config";
import { Polygon } from "@thirdweb-dev/chains";
import SwapTokenAssets from "./components/Modals/SwapTokenAssets";
import { IsWalletAddressesEqual } from "./common/utility";
const wallet = new EmbeddedWallet({
  clientId: `${variables.thirdweb_client_id}`,
  chain: Polygon,
});

function App() {
  document.title = "SOLOS";
  const auth = localStorage.getItem("reg-data");
  // console.log(JSON.parse(auth), "JSON.parse(auth)");
  const address = useAddress();
  const connect = useConnect();
  apiHeader();
  useEffect(() => {
    let res = JSON.parse(auth);
    // console.log(res, "workign");
    if (res) {
      wallet
        .authenticate({
          strategy: "auth_endpoint",
          payload: JSON.stringify({ userId: `${res?.data?._id}` }),
          encryptionKey: `${res?.data?.walletRecoveryKey}`,
          recoveryCode: `${res?.data?.walletRecoveryKey}`,
        })
        .then((authResult) => {
          console.log(authResult, "authResult");
          connect(embeddedWallet({}), {
            authResult,
          })
            .then((res) => {
              console.log(res, "connection success");
            })
            .catch((err) => {
              console.log(err, "connection error");
            });
        })
        .catch((err) => {
          console.log(err, "authenticate error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (auth) {
      getdata().then((user) => {
        if (user) {
          if (address) {
            let hasAddress = user?.walletAddress;
            if (!hasAddress || !IsWalletAddressesEqual(hasAddress, address)) {
              UpdateProfile({ walletAddress: address })
                .then((response) => {
                  if (response.status === 200) {
                    getdata();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const getdata = async () => {
    let user = JSON.parse(auth);
    let token = user?.data?.token;
    // console.log(token, "token");

    return await getMyInfoAxios(token)
      .then((res) => {
        // console.log(
        //   +res?.data?.isKYCConfirmed === 1 ? "true" : "false",
        //   "res?.data"
        // );
        localStorage.setItem(
          "isKycCompleted",
          +res?.data?.isKYCConfirmed === 1 ? "true" : "false"
        );
        // console.log(res.data, "res.data");
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
        return null;
      });
  };

  onMessageListener()
    .then((payload) => {
      // console.log("Received foreground message: ", payload);
      // const {
      //   notification: { title, body },
      // } = payload;
      // // toast(<ToastifyNotification title={title} body={body} />);
      // alert(body)
    })
    .catch((err) =>
      console.log("An error occured while retrieving foreground message. ", err)
    );

  // const ToastifyNotification = ({ title, body }) => (
  //   <div className="push-notification">
  //     <h2 className="push-notification-title">{title}</h2>
  //     <p className="push-notification-text">{body}</p>
  //   </div>
  // );

  let element = useRoutes(UseRoute(auth));
  return <>
    <SwapTokenAssets />
    {element}
  </>;
}

export default App;
