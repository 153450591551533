import React, { useEffect, useState, useRef, useContext } from "react";
import "./TopNav.css";
import Dummy from "../../images/userDummy2.png";
import Notification from "../../images/notification.png";
import nav1 from "../../images/nav1.svg";
import nav2 from "../../images/nav2.svg";
import nav3 from "../../images/nav3.svg";
import nav4 from "../../images/nav4.svg";
import nav5 from "../../images/nav5.svg";
import logoutIcon from "../../images/logoutIcon.svg";
import tick from "../../images/tick-success.png";
import { Link, useNavigate } from "react-router-dom";
import { getMyInfo } from "../../services/propertyServices";
import { FaBars } from "react-icons/fa";
import { fireToast } from "../../common/Toster";
import Dropdown from "react-bootstrap/Dropdown";
import { BiError } from "react-icons/bi";
import ReferModal from "../Modals/referModal";
import { ConversionContext } from "../../Context/context";
const TopNav = ({ title, setCustom, custom }) => {
  const divRef = useRef(null);
  const [showText, setShowText] = useState(false);
  const [show, setShow] = useState(false);
  const { setShowKyc } = useContext(ConversionContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem("contact-info");
    localStorage.removeItem("user-info");
    localStorage.removeItem("reg-data");
    localStorage.removeItem("isKycCompleted");
    // navigate("/login");
    // window.open("https://solosfi.com/", "_parent");
  };

  useEffect(() => {
    getdata();
  }, []);
  const [data, setData] = useState("");
  const getdata = async () => {
    const myData = await getMyInfo()
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err, "err");
        handleLogout()
        navigate("/login");
      });
  };
  const handleClickOutside = (e) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      setShowText(!showText);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="top-nav position-relative pt-xl-5 py-3 px-md-4 px-3 pb-xl-4 border-bottom d-flex align-items-center justify-content-between">
        <div className="nav-left">
          <span
            className="align-middle cursor-pointer"
            onClick={() => setCustom(!custom)}
          >
            <FaBars />
          </span>
          <h1 className="ms-md-4 fs-24 mb-0 align-middle text-capitalize d-inline-block">
            {title.replace("-", " ")}
          </h1>
          {/* <p className="f-15">Good momrning Max!</p> */}
        </div>
        <div className="nav-right">
          {/* {console.log(data?.isKYCConfirmed, "res?.da")} */}
          {data?.isKYCConfirmed == 1 ? (
            <h6
              style={{ height: "100%" }}
              className="livenessBtn btn border-primary"
            >
              <img src={tick} alt="" style={{ width: "25px" }} /> Liveliness
              Checked!
            </h6>
          ) : (
            <Link
              style={{ height: "100%" }}
              onClick={() => setShowKyc(true)}
              className="btn border-primary livenessBtn"
            >
              <BiError color="red" /> Liveliness Pending
            </Link>
          )}

          {/* <MpcWallet /> */}
          {/* <ConnectWallet /> */}

          <Dropdown>
            <Dropdown.Toggle
              className="bg-transparent border-0 p-0"
              id="notify"
            >
              <div className="position-relative">
                <img className="notification-icon" src={Notification} alt="" />
                <span
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    height: "10px",
                    width: "10px",
                    backgroundColor: "green",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="user-droupdown">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="ps-3">
                    <h3 className="f-18 m-0">Notifications</h3>
                  </div>
                </div>
                <ul className="user-option border-top mt-3 pt-4">
                  <li className="notification">
                    <a>
                      <i className="me-3">
                        <img width={20} src={Notification} alt="" />
                      </i>
                      <span>Transaction History</span>{" "}
                    </a>
                  </li>
                  <li className="notification">
                    <a>
                      <i className="me-3">
                        <img width={20} src={Notification} alt="" />
                      </i>
                      <span>Transaction History</span>{" "}
                    </a>
                  </li>
                  <li className="notification">
                    <a>
                      <i className="me-3">
                        <img width={20} src={Notification} alt="" />
                      </i>
                      <span>Transaction History</span>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle
              className="bg-transparent border-0 p-0"
              id="dropdown-basic"
            >
              <div className="user-icon">
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    border: "1px solid gray",
                  }}
                  src={data?.profileImage == null ? Dummy : data?.profileImage}
                  alt=""
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="user-droupdown" ref={divRef}>
                <div className="d-flex align-items-center">
                  <img
                    style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                      border: "1px solid gray",
                    }}
                    src={data?.profileImage == null ? Dummy : data?.profileImage}
                    alt=""
                  />
                  <div className="ps-3">
                    <h3 className="f-18 mb-0">
                      {data?.name} {data?.name && data?.lastname}
                    </h3>
                    <small>{data?.userName}</small>
                    <a href="/Settings">
                      <span className="edit-btn">Edit Profile</span>
                    </a>
                  </div>
                </div>
                <ul className="user-option border-top mt-4 pt-4">
                  <li>
                    <a href="/transactions">
                      <i className="me-3">
                        <img src={nav1} alt="" />
                      </i>
                      <span>Transaction History</span>{" "}
                    </a>
                  </li>
                  <li
                    onClick={() =>
                      fireToast(
                        "info",
                        "Add Account functionality is work in process for now."
                      )
                    }
                  >
                    <a href="#">
                      <i className="me-3">
                        <img src={nav2} alt="" />
                      </i>
                      <span>Add Account</span>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/Settings">
                      <i className="me-3">
                        <img src={nav3} alt="" />
                      </i>
                      <span>Switch Account</span>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => setShow(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="me-3">
                        <img src={nav4} alt="" />
                      </i>
                      <span>Invite Friend</span>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/support">
                      <i className="me-3">
                        <img src={nav5} alt="" />
                      </i>
                      <span>Support</span>{" "}
                    </a>
                  </li>
                  {/* <li>
                  <a href="/">
                    <i className="me-3">
                      <img src={nav6} alt="" />
                    </i>
                    <span>Security</span>{" "}
                  </a>
                </li> */}
                  <li className="logout">
                    <a href="https://solosfi.com/">
                      <i className="me-3">
                        <img src={logoutIcon} alt="" />
                      </i>
                      <button
                        className="bg-transparent border-0"
                        onClick={handleLogout}
                      >
                        <span>Logout</span>
                      </button>{" "}
                    </a>
                  </li>
                  {/* <li className="logout"><a href="/"><i className="me-3"><img src={nav7} alt="" /></i><button><span>Logout</span></button> </a></li> */}
                </ul>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <ReferModal show={show} setShow={setShow} />
    </>
  );
};

export default TopNav;
