import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Dummy from "../../images/userDummy2.png";
import { ConversionContext } from "../../Context/context";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { MdOutlineGroupAdd } from "react-icons/md";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { addContact, getAllClientUsers, getAllContacts } from "../../services/propertyServices";
import { Form } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { fireToast } from "../../common/Toster";

function Contacts({
  setUserName,
  setUserFullName,
  setWalletAddress
}) {
  const { showContacts, setShowContacts } = useContext(ConversionContext);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("ALL");
  const [showForm, setShowForm] = useState(false);
  const [userData, setUserData] = useState([]);
  const fetchUserData = async (
    pageNumber = 1,
    pageSize = 5
  ) => {
    setIsLoading(true);
    if (tab === 'ALL') {
      try {
        const list = await getAllClientUsers(pageNumber, pageSize, search);
        if (list.status === 200) {
          setUserData(list?.data?.items);
          setIsLoading(false);
        } else {
          throw new Error(list.error);
        }
      } catch (err) { setIsLoading(false); }
    } else {
      try {
        const list = await getAllContacts(pageNumber, pageSize, search);
        if (list.status === 200) {
          setUserData(list?.data?.items);
          setIsLoading(false);
        } else {
          throw new Error(list.error);
        }
      } catch (err) { setIsLoading(false); }
    }
  };
  const AddSchema = Yup.object().shape({
    userName: Yup.string()
      .required(`username is required`),
    walletAddress: Yup.string()
      .min("42")
      .max("42")
      .required("Wallet Address is required"),
  });

  const onFormSubmit = async ({
    userName,
    walletAddress
  }, { resetForm }) => {
    try {
      setIsLoading(true);
      const response = await addContact({
        userName,
        walletAddress
      });
      if (response.status == 200) {
        resetForm();
        setShowForm(false);
        setIsLoading(false);
        fetchUserData();
        fireToast("success", "Contact added successfully.");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error)
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    }
  };
  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, tab]);

  return (
    <Modal
      onShow={() => {
        setSearch("");
        setTab("ALL")
        setShowForm(false);
      }}
      show={showContacts}
      onHide={() => {
        setTab("ALL")
        setShowContacts(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">{showForm && <IoChevronBackCircleOutline className="cursor-pointer" onClick={() => setShowForm(false)} fontSize={22} />} {showForm ? <span>New contact</span> : <span>Select User</span>}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border border-0 pt-0">
        {
          !showForm && (
            <div className="d-flex justify-content-center align-items-center my-2">
              <Tabs
                defaultActiveKey={tab}
                onSelect={(k) => {
                  setTab(k)
                  setShowForm(false)
                }}
                id="send_tabs"
                className="marketplace-tabs border-0"
              >
                <Tab eventKey="ALL" title="ALL"></Tab>
                <Tab eventKey="CONTACTS" title="CONTACTS"></Tab>
              </Tabs>
            </div>
          )
        }
        <div className="tokensearch my-2">
          {
            !showForm &&
            <div className="position-relative">
              <input
                type="search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                class="form-control"
                placeholder="Search name or paste address"
              ></input>
              {tab === "CONTACTS" && (
                <i
                  className="edit-icon"
                  onClick={() => setShowForm(true)}
                >
                  <MdOutlineGroupAdd fontSize={24} color="black" />
                </i>
              )}
            </div>
          }
        </div>
        <div className="popular-tokens">
          {
            showForm ?
              <div>
                <Formik
                  initialValues={{
                    userName: "",
                    walletAddress: "",
                  }}
                  validationSchema={AddSchema}
                  onSubmit={onFormSubmit}
                >
                  {({ handleSubmit, errors, touched, values, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="my-2">
                        <label>Username</label>
                        <div className=" d-flex">
                          <Field
                            type="text"
                            autoComplete="off"
                            name="userName"
                            className="form-control"
                          />
                        </div>
                        {errors.userName && touched.userName ? (
                          <div className="text-danger fw-normal fs-12">
                            {errors?.userName}
                          </div>
                        ) : null}
                      </div>
                      <div className="my-2">
                        <label>Ethereum public address</label>
                        <div className=" d-flex">
                          <Field
                            type="text"
                            autoComplete="off"
                            name="walletAddress"
                            placeholder="Enter public address (0x)"
                            className="form-control"
                          />
                        </div>
                        {errors.walletAddress && touched.walletAddress ? (
                          <div className="text-danger fw-normal fs-12">
                            {errors?.walletAddress}
                          </div>
                        ) : null}
                      </div>
                      <button
                        disabled={isLoading}
                        class="btn btn-success rounded rounded-4 w-100 my-2 py-2"
                        type="submit"
                      >
                        {
                          isLoading ?
                            <span className="typeWriter">
                              processing<span> . . . . .</span>
                            </span> :
                            <>Add Contact</>
                        }
                      </button>
                    </Form>)}
                </Formik>
              </div>
              :
              <div className="token-list" style={{
                minHeight: '175px'
              }}>
                {
                  isLoading ?
                    <h6 className="fs-5 text-primary text-center">
                      <span className="typeWriter">
                        Loading<span> . . . . .</span>
                      </span>
                    </h6>
                    :
                    (
                      userData?.length === 0 ? (
                        <h6 className="text-danger text-center">No Data Found.</h6>
                      ) : (
                        userData?.map((item, i) => (
                          <div
                            key={i}
                            style={{
                              padding: "5px 0px",
                              borderBottom: "1px solid #2c29294d",
                            }}
                            onClick={() => {
                              setUserFullName(`${tab === "ALL" ? 'Solos User' : 'Contact'} : ${item?.userName}`);
                              setWalletAddress(item?.walletAddress);
                              setUserName(item?.userName);
                              setShowContacts(false);
                              setTab("ALL")
                            }}
                            className="d-flex align-items-center my-2">
                            <img
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: "1px solid gray",
                              }}
                              src={item?.profileImage == null ? Dummy : item?.profileImage}
                              alt=""
                            />
                            <div className="ps-3">
                              <h3 className="f-16 mb-0 info-username">
                                {item?.userName && item?.userName}
                              </h3>
                              <small className=" text-break">{item?.walletAddress}</small>
                            </div>
                          </div>
                        ))
                      )
                    )
                }
              </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Contacts;
