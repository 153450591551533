import React, { useEffect, useState } from "react";
import "./assets.css";
import icon from "../../images/solos.jpeg";
import { getSoloAssets } from "../../services/propertyServices";
import { useAddress } from "@thirdweb-dev/react";
import Balancecard from "../Balancecard/Balancecard";
import SyncLoading from "../loader/syncLoader";

const MyAssets = () => {
  const address = useAddress();
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (address) {
      getSoloAssets(address)
        .then((response) => {
          // console.log(response, "getSoloAssets");
          const allAssets = response?.data;
          const allAssetsWithoutTest = response?.data?.filter(
            (tvt) => !tvt?.["name"]?.toLowerCase()?.includes("test")
          );
          setItems(
            // window.location.hostname.includes("localhost") ?
            allAssets
            // : allAssetsWithoutTest
          );
          // console.log(response?.data, "response.dtaa");
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
    // console.log(address, "address");
  }, [address]);
  const balance = items?.reduce((sum, item) => {
    // console.log(item?.priceInUsdc, "conversionrate123");
    // let cr = +(item?.conversionrate || 1);
    let bl = +item?.priceInUsdc;
    return sum + Number(bl > 0 ? bl : 0);
  }, 0);
  // console.log(balance, "balance");
  return (
    <>
      <div className=" mb-xl-2 pb-5">
        {address ? (
          <Balancecard balance={balance} address={address} />
        ) : (
          <div className="mb-3 mb-lg-4 text-center">Unlocking Wallet...</div>
        )}
      </div>
      <div className="table-responsive transaction-data">
        <table className="w-100 table">
          <thead>
            <tr>
              <td
                colSpan={3}
                className="ps-3 fw-600"
                style={{ fontSize: "16px" }}
              >
                My Solos Wallet Balances
              </td>
            </tr>
          </thead>
          <tbody>
            {items?.length > 0 ? (
              items?.map((item, i) => (
                <tr
                  style={{ cursor: "pointer", verticalAlign: "middle" }}
                  key={i}
                >
                  <td className="ps-3">
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`https://polygonscan.com/token/${item?.contractAddress}`}
                    >
                      <img
                        src={item?.image || icon}
                        width={40}
                        alt=""
                        className="rounded-circle"
                      />
                    </a>
                  </td>
                  <td className="px-1" style={{ fontSize: "smaller" }}>
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`https://polygonscan.com/token/${item?.contractAddress}`}
                    >
                      <p style={{ fontSize: "15px" }}>
                        {item?.categories} {item?.series}
                      </p>
                    </a>
                  </td>
                  <td>
                    <b className="text-start text-muted">
                      {Number(
                        Number(item?.balance)
                          .toString()
                          .match(/^-?\d+(?:.\d{0,2})?/)[0]
                      ).toLocaleString()}{" "}
                      <span style={{ fontSize: "15px" }}>{item?.symbol}</span>
                    </b>
                  </td>
                </tr>
              ))
            ) : (
              <SyncLoading />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MyAssets;
