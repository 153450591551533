import React, { useContext, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { ConversionContext } from '../Context/context';

function SwapAssetsDropdown() {
    const { tokenToSend, swapAssets, setTokenToSend } = useContext(ConversionContext);
    const [searchAssets, setSearchAssets] = useState(swapAssets);
    const [search, setSearch] = useState("");

    const handleSearch = (value) => {
        setSearchAssets(
            swapAssets.filter(
                (item) =>
                    item.symbol?.toLowerCase().includes(value.toLowerCase()) ||
                    item.categories?.toLowerCase().includes(value.toLowerCase()) ||
                    item.contractAddress?.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    return (
        <Dropdown>
            <Dropdown.Toggle className='form-control bg-transparent mt-2 d-flex justify-content-between align-items-center text-dark'>
                <div className=' bg-transparent'>
                    <button
                        type="button"
                        className="btn btn-modal bg-transparent text-dark"
                    >
                        <img className='rounded-circle' alt="" src={tokenToSend?.image} />
                        {tokenToSend?.symbol}
                    </button>
                </div>
                <div className='ms-auto me-2'>
                    {Number(
                        Number(tokenToSend?.balance)
                            .toString()
                            .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                    ).toLocaleString()} {tokenToSend?.symbol}{" "}
                    (≈ $
                    {tokenToSend
                        ? Number(
                            Number(+tokenToSend?.balance * +(tokenToSend?.conversionrate || 1))
                                .toString()
                                .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                        ).toLocaleString()
                        : 0}
                    )
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className='w-100'>
                <div className="tokensearch my-2 mx-2">
                    <input
                        type="search"
                        value={search}
                        onChange={(e) => {
                            handleSearch(e.target.value);
                            setSearch(e.target.value);
                        }}
                        class="form-control"
                        placeholder="Search name or paste address"
                    ></input>
                </div>
                <div className="popular-tokens">
                    <div className="token-list">
                        {search && searchAssets.length === 0 ? (
                            <h6 className="text-danger text-center">No Asset Found !</h6>
                        ) : (
                            (search ? searchAssets : swapAssets).map((item, i) => {
                                return (
                                    <Dropdown.Item eventKey={i} key={i} onClick={() => {
                                        setTokenToSend(item);
                                        setSearch("");
                                    }}>
                                        <div
                                            className="tokenitems"
                                        >
                                            <div className="token-image">
                                                <img className='rounded-circle' src={item?.image} alt=""></img>
                                            </div>
                                            <div className="token-name">
                                                <h6 className="mb-1">{item?.symbol}</h6>
                                                <div className="d-flex justify-content-between">
                                                    <span>
                                                        {item?.categories} {item?.series}
                                                    </span>
                                                    <span className="">
                                                        {item?.balance
                                                            ? Number(
                                                                Number(item?.balance)
                                                                    .toString()
                                                                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                                                            ).toLocaleString()
                                                            : 0}
                                                        (≈ $
                                                        {item?.conversionrate
                                                            ? Number(
                                                                Number(+item?.balance * +item?.conversionrate)
                                                                    .toString()
                                                                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                                                            ).toLocaleString()
                                                            : 0}
                                                        )
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                )
                            }))}
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown >
    )
}

export default SwapAssetsDropdown

