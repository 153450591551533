import React, { useState, useContext } from "react";
import "./Kyc.css";
import Button from "react-bootstrap/Button";
import logo from "../../images/site-logo.svg";
import kyc from "../../images/kycNew.png";
import { ConversionContext } from "../../Context/context";
const Kyc = () => {
  const [check, setCheck] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
  });
  const { setShowKyc } = useContext(ConversionContext);
  const loginResponse = JSON.parse(localStorage.getItem("reg-data"));
  const token = loginResponse?.data?.token;
  console.log(loginResponse, "token");
  const onSubmit = () => {
    window.location.href = `https://facetec.solosfi.com/?access_token=${token}`;
  };
  return (
    <div className="kyc-page">
      {/* <div className="text-center pt-lg-5">
        <img className="mx-auto" src={logo} alt="" />
      </div> */}
      <div className="text-center mb-2">
        <h1 className="fs-32 mt-3">KYC</h1>
        <div className="kyc-img">
          {" "}
          <img className="mx-auto" src={kyc} alt="" />
        </div>
      </div>
      <div className="auth-card py-lg-3">
        <div className="kyc-block p-3">
          <div class="d-flex align-items-baseline mb-2 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
              onChange={(e) => setCheck({ ...check, check1: e.target.checked })}
            />
            <label class="mx-2 custom-control-label" for="customCheck1">
              Position your face for scanning from two different positions.
            </label>
          </div>
          <div class="d-flex align-items-baseline mb-2 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              onChange={(e) => setCheck({ ...check, check2: e.target.checked })}
              id="customCheck1"
            />
            <label class="mx-2 custom-control-label" for="customCheck1">
              Ensure proper lighting and alignment with the camera.
            </label>
          </div>
          <div class="d-flex align-items-baseline mb-2 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              onChange={(e) => setCheck({ ...check, check3: e.target.checked })}
              id="customCheck1"
            />
            <label class="mx-2 custom-control-label" for="customCheck1">
              Follow on-screen instructions for the first designated position.
            </label>
          </div>
          <div class="d-flex align-items-baseline mb-2 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
              onChange={(e) => setCheck({ ...check, check4: e.target.checked })}
            />
            <label class="mx-2 custom-control-label" for="customCheck1">
              Complete the initial scan before adjusting for the second angle.
            </label>
          </div>
          <div class="d-flex align-items-baseline mb-2 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
              onChange={(e) => setCheck({ ...check, check5: e.target.checked })}
            />
            <label class="mx-2 custom-control-label" for="customCheck1">
              Align your face accurately to capture facial features effectively.
              You may need to remove your glasses as the scan needs to see your
              eyes.
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex my-3 justify-content-center">
        <button
          className="btn btn-outline-primary px-5 mx-2"
          variant="primary"
          type="submit"
          onClick={() => setShowKyc(false)}
        >
          Skip
        </button>
        <Button
          className="btn btn-primary px-5 mx-2"
          variant="primary"
          type="submit"
          disabled={
            !check.check1 ||
            !check.check2 ||
            !check.check3 ||
            !check.check4 ||
            !check.check5
          }
          onClick={onSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Kyc;
