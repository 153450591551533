import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import RedTokenAbi from "../Abis/RedTokenAbi.json";
import {
  getAllPlatformTvts,
  getFredBuyedByUser,
  getSfredBuyedByUser,
} from "../services/NftServices";
import Pagination from "@mui/material/Pagination";
import Loader from "../components/loader/loader";
import NoData from "../components/NoDataComp/NoData";
import UserSuperFredCard from "../components/SuperFred/UserSuperFredCard";
import UserTvtCard from "../components/Tvt/UserTvtCard";
import { fireToast } from "../common/Toster";
import { ethers } from "ethers";
import axios from "axios";
import Web3, { providers } from "web3";
import BuyNftCard from "../components/Nft/BuyNftCard";
import BuyFredCard from "../components/Fred/BuyFredCard";
import {
  getNftsBuyedByUser,
} from "../services/NftServices";
import { useAddress } from "@thirdweb-dev/react-core";

const UserAssets = () => {
  const [loading, setLoading] = useState(true);
  const [dbNfts, setDbNfts] = useState([]);
  const [dbFreds, setDbFreds] = useState([]);
  const [possestionsNfts, setPossestionsNfts] = useState([]);
  const [possestionsFreds, setPossestionsFreds] = useState([]);
  const [address, setAddress] = useState("");
  const [nftOwnedByUser, setNftOwnedByUser] = useState([]);
  const [nftBuyByUser, setNftBuyByUser] = useState([]);
  const [superFredData, setSuperFredData] = useState([]);
  const [tvtData, setTvtData] = useState([]);

  const fetchUserSuperFreds = async () => {
    try {
      // setLoading(true);
      const response = await getSfredBuyedByUser(MyAddress);
      if (response.status === 200) {
        setSuperFredData(response?.data);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching super fred data:", error);
    }
  };
  const MyAddress = useAddress();
  const fetchuserTvts = async () => {
    try {
      setLoading(true);
      const response = await getAllPlatformTvts(MyAddress);

      if (response.status === 200) {
        // console.log(response, "???????????????????");
        // const allTvtData = response?.data?.result?.items;
        // let result = [];
        // for (let index = 0; index < allTvtData.length; index++) {
        //   const element = allTvtData[index];
        //   console.log(element, "element");
        //   let res = await getWallet(element?.contractAddress);
        //   console.log(res, "resssssss");
        //   if (res > 0) {
        //     result?.push({ ...element, balance: res });
        //   }
        // }
        // console.log(result, "results");
        const allTvtData = response?.data;
        const allTvtDataWithoutTest = response?.data?.filter(
          (tvt) => !tvt?.["name"]?.toLowerCase()?.includes("test")
        );
        setTvtData(
          // window.location.hostname.includes("localhost") ?
          allTvtData
          // : allTvtDataWithoutTest
        );
        // setTvtData(response?.data?.result?.items);
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const fetchBuyedNftsFreds = async () => {
    try {
      const FredBuyedResponse = await getFredBuyedByUser(MyAddress);
      if (FredBuyedResponse.status === 200) {
        // console.log(FredBuyedResponse.data, "FredBuyedResponse");
        setDbFreds(FredBuyedResponse?.data);
      } else {
        throw new Error(FredBuyedResponse.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const getAllNftsByUser = async () => {
    // if (
    //   window.web3 &&
    //   window.web3.currentProvider &&
    //   window.web3.currentProvider.isCoinbaseWallet
    // ) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
        // params: [{ chainId: '0x5' }],
      });
    } catch (e) {
      // fireToast("error", "USER REJECTED THE REQUEST...");
      setLoading(false);
    }
    const provider = new ethers.providers.Web3Provider(
      window.web3.currentProvider
    );

    // console.log("starting.....");

    try {
      // console.log("try catch starting.....");

      await provider
        .send("eth_requestAccounts", [])
        .then(async (res) => {
          // console.log("provider .....");

          setAddress(res?.[0] ? res?.[0] : res?.result?.[0]);
          try {
            // console.log("working before api hit");
            const response = await axios({
              method: "get",
              // url: `https://polygon-mumbai.g.alchemy.com/nft/v2/rs8xNqu1taW0yiUL4sCgqJwSyrtpywGt/getNFTs?owner=${res?.[0]?res?.[0]:res?.result?.[0]}`,
              url: `https://polygon-mainnet.g.alchemy.com/nft/v2/rvmpmREwuUjwDRUj3SBmkP6EDR0rSlVo/getNFTs?owner=${res?.[0] ? res?.[0] : res?.result?.[0]
                }`,
            })
              .then(async (result1) => {
                // console.log(result,'result')
                // console.log("working after api hit");
                try {
                  const response = await axios({
                    method: "get",
                    // url: `https://polygon-mumbai.g.alchemy.com/nft/v2/rs8xNqu1taW0yiUL4sCgqJwSyrtpywGt/getNFTs?owner=${res?.[0]?res?.[0]:res?.result?.[0]}&pageKey=${result1?.data?.pageKey}`,
                    url: `https://polygon-mainnet.g.alchemy.com/nft/v2/rvmpmREwuUjwDRUj3SBmkP6EDR0rSlVo/getNFTs?owner=${res?.[0] ? res?.[0] : res?.result?.[0]
                      }&pageKey=${result1?.data?.pageKey}`,
                  }).then((result) => {
                    setNftOwnedByUser(
                      result1?.data?.ownedNfts.concat(result?.data?.ownedNfts)
                    );
                    // console.log(
                    //   result1?.data?.ownedNfts.concat(result?.data?.ownedNfts),
                    //   "result1"
                    // );

                    let ownNfts = result1?.data?.ownedNfts.concat(
                      result?.data?.ownedNfts
                    );
                    let myOwnNFTAddress = [];
                    for (let index = 0; index < ownNfts?.length; index++) {
                      const element = ownNfts[index];
                      myOwnNFTAddress.push(
                        element?.contract?.address?.toLowerCase()
                      );
                    }
                    // console.log(ownNfts,'ownNfts')
                    let myWalletNFT = [];
                    for (let index = 0; index < dbNfts?.length; index++) {
                      const element = dbNfts[index];

                      // console.log(element, element.contractAddress, "db nft");
                      if (
                        myOwnNFTAddress.includes(
                          element.contractAddress?.toLowerCase()
                        )
                      ) {
                        // console.log("true case");
                        myWalletNFT.push(element);
                      }
                    }
                    let myWalletFRED = [];
                    for (let index = 0; index < dbFreds?.length; index++) {
                      const element = dbFreds[index];
                      // console.log(element, element.contractAddress, "db nft")
                      if (
                        myOwnNFTAddress.includes(
                          element.contractAddress?.toLowerCase()
                        )
                      ) {
                        // console.log("true case")
                        myWalletFRED.push(element);
                      }
                    }

                    // let myWalletTVT = [];
                    // for (let index = 0; index < tvtData?.length; index++) {
                    //   const element = tvtData[index];
                    //   console.log(element, element.contractAddress, "db tvt")
                    //   if (
                    //     myOwnNFTAddress.includes(
                    //       element.contractAddress?.toLowerCase()
                    //     )
                    //   ) {
                    //     // console.log("true case")
                    //     myWalletTVT.push(element);
                    //   }
                    // }

                    const walletNftResult = myWalletNFT;
                    const walletFredResult = myWalletFRED;
                    // const walletTvtResult = myWalletTVT;
                    // console.log(walletNftResult, "walletNftResult");

                    setPossestionsNfts(walletNftResult);
                    setPossestionsFreds(walletFredResult);
                    // setPossestionsTvts(walletTvtResult)

                    // try {
                    //   const NftBuyedResponse = await getNftsBuyedByUser(1, 100);
                    //   if (NftBuyedResponse.status === 200) {
                    //    console.log(NftBuyedResponse,'NftBuyedResponse')

                    //    let ownNfts = result?.data?.ownedNfts;
                    //    let myOwnNFTAddress = [];
                    //    for (let index = 0; index < ownNfts.length; index++) {
                    //     const element = ownNfts[index];
                    //     myOwnNFTAddress.push(element?.contract?.address.toLowerCase())
                    //    }

                    //    console.log(myOwnNFTAddress, "myOwnNFTAddress")

                    //    let dbNFTResponse = NftBuyedResponse?.data?.items;
                    //    let myWalletNFT = [];

                    //    for (let index = 0; index < dbNFTResponse.length; index++) {
                    //     const element = dbNFTResponse[index];

                    //     console.log(element, element.contractAddress, "db nft")

                    //     if(myOwnNFTAddress.includes(element.contractAddress.toLowerCase())){

                    //       console.log("true case")
                    //       myWalletNFT.push( element );
                    //     }
                    //    }

                    //    console.log(myWalletNFT, "final myWalletNFT")

                    //   } else {
                    //     throw new Error(NftBuyedResponse.error);
                    //   }
                    // } catch (error) {
                    //   console.error("Error fetching nft data:", error);
                    // }
                    setTimeout(() => {
                      setLoading(false);
                    }, 2000);
                  });
                } catch (error1) {
                  fireToast("error", error1);
                  console.log(error1);
                }
              })
              .catch((err) => {
                console.log("catch after api hit");
                console.log(err, "error fetching nfts");
                setLoading(false);
              });
          } catch (error) {
            fireToast("error", error);
            console.log(error);
          }
        })
        .catch((e) => {
          console.log(e, ">>>>>>>>>>>>ERROR");
          setLoading(false);
        });
    } catch (error) { }
  };
  // useEffect(() => {
  //   getAllNftsByUser();
  // }, [apiHit]);

  const getWallet = async (value) => {
    if (window.web3) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      } catch (e) {
        // fireToast("error", "Request Rejected.");
      }
    }
    const web3 = (window.web3 = new Web3(window.ethereum));
    var contractInstance = "";
    contractInstance = new web3.eth.Contract(RedTokenAbi, value, providers);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    var account = accounts?.[0] ? accounts?.[0] : accounts?.result?.[0];
    const RedBalance = await contractInstance._methods
      .balanceOf(account)
      .call();
    // console.log(RedBalance.toString(), "RedBalance");
    let redBal = RedBalance.toString();
    redBal = Number(redBal) / 10 ** 18;
    // redBal = Math.round(redBal);
    return redBal;
  };

  useEffect(() => {
    fetchBuyedNftsFreds();
    fetchUserSuperFreds();
    fetchuserTvts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MyAddress]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="ps-xxl-4">
        <div className="dashboard-centerd">
          <div className="row g-2">
            <div className="col-xl-12 col-xxl-12 pe-lg-4 pt-4 border-lg-end center-height pb-lg-5 mb-4">
              <div className="">
                <div className="row border-bottom mb-4 g-2">
                  <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                    <h6 className="fw-600 text-black">TVTs</h6>
                    {tvtData?.length > 0 && (
                      <Link className="view-btn" to="/all-buy-tvts">
                        View All <BiChevronRight />
                      </Link>
                    )}
                  </div>
                  {tvtData?.length > 0 ? (
                    tvtData?.slice(0, 3)?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                        >
                          <UserTvtCard
                            data={item}
                            fetchData={fetchuserTvts}
                            setLoading={setLoading}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <NoData />
                  )}
                  {/* {tvtData?.length > 0 ? (
                    <>
                      {tvtData?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="col-xxl-4 col-lg-6 col-12 mb-4"
                          >
                            <UserTvtCard
                              data={item}
                              fetchData={fetchuserTvts}
                              setLoading={setLoading}
                            />
                          </div>
                        );
                      })}
                      {totalData > 12 && (
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            // minWidth: "200%",
                          }}
                        >
                          <Pagination
                            color="primary"
                            count={Math.ceil(totalData / 12)}
                            page={page}
                            onChange={(e, v) => setPage(v)}
                            // activePage={page}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <NoData />
                  )} */}
                </div>
                <div className="row border-bottom mb-4 g-2">
                  <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                    <h6 className="fw-600 text-black">Your FREDs</h6>
                    {dbFreds?.length > 0 && (
                      <Link className="view-btn" to="/all-buy-freds">
                        View All <BiChevronRight />
                      </Link>
                    )}
                  </div>
                  {dbFreds?.length > 0 ? (
                    dbFreds?.slice(0, 3)?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                        >
                          <BuyFredCard
                            data={item}
                            fetchData={fetchBuyedNftsFreds}
                            setLoading={setLoading}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <NoData />
                  )}
                </div>
                <div className="row border-bottom mb-4 g-2">
                  <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                    <h6 className="fw-600 text-black">Super FREDs</h6>
                    {superFredData?.length > 0 && (
                      <Link className="view-btn" to="/all-buy-sfreds">
                        View All <BiChevronRight />
                      </Link>
                    )}
                  </div>
                  {superFredData?.slice(0, 3)?.length > 0 ? (
                    <>
                      {superFredData?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                          >
                            <UserSuperFredCard
                              data={item}
                              fetchData={fetchUserSuperFreds}
                              setLoading={setLoading}
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
                <div className="row border-bottom mb-4 g-2">
                  <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                    <h6 className="fw-600 text-black">Your Possessions</h6>
                    {possestionsNfts?.length > 0 && (
                      <Link className="view-btn" to="/all-buy-nfts">
                        View All <BiChevronRight />
                      </Link>
                    )}
                  </div>
                  {possestionsNfts?.length > 0 ? (
                    possestionsNfts?.slice(0, 3)?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4 card-cols"
                        >
                          <BuyNftCard
                            data={item}
                            // fetchData={fetchBuyedNftsFreds}
                            setLoading={setLoading}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAssets;
