import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-phone-input-2/lib/style.css";
import logo from "../images/r-logo.svg";
import "./custom.css";
import { Link, useNavigate } from "react-router-dom";
import { userLogin } from "../services/userService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fireToast } from "../common/Toster";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { getFirebaseToken } from "../firebase/firebase";
import { ConversionContext } from "../Context/context";

const SignIn = () => {
  const { setShowKyc } = useContext(ConversionContext);
  const [passwordType, setPasswordType] = useState("password");
  let navigate = useNavigate();
  const [token, setToken] = useState("");
  const [process, setProcess] = useState(false);

  useEffect(() => {
    logoutFun();
  }, []);

  const logoutFun = () => {
    // console.log("Testing 22222222");
    localStorage.clear();
    localStorage.removeItem("user-info");
    localStorage.removeItem("contact-info");
    localStorage.removeItem("reg-data");
    localStorage.removeItem("isKycCompleted");
  };

  useEffect(() => {
    getFirebaseToken().then((firebaseToken) => {
      setToken(firebaseToken);
    });
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      device_token: token,
      device_type: "web",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email must be a valid email")
        .required("This Field is Required"),
      password: Yup.string().required("This Field is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setProcess(true);

        const loginResponse = await userLogin(values);
        if (loginResponse.status === 200) {
          fireToast("success", loginResponse.message);
          localStorage.setItem("reg-data", JSON.stringify(loginResponse));
          localStorage.setItem("user-info", JSON.stringify(loginResponse));
          if (
            loginResponse?.data?.isKYCConfirmed === 1 ||
            loginResponse?.data?.isKYCConfirmed === "1"
          ) {
            localStorage.setItem("isKycCompleted", "true");
            window.location.href = "/dashboard";
          } else if (loginResponse?.data?.name) {
            navigate("/dashboard");
            localStorage.removeItem("isKycCompleted");
            setShowKyc(true);
          }
        } else {
          setProcess(false);
          throw new Error(loginResponse.error);
        }
      } catch (err) {
        setProcess(false);
        console.log(err, "error on signIn");
        fireToast("error", err.response.data.error);
      }
    },
  });

  return (
    <>
      <div className="auth-bg sign-in mh-100 d-flex align-items-center justify-content-center">
        <div className="auth-card py-lg-3">
          <div className="auth-inner">
            <div className="text-center mb-2 ">
              <Link to="/">
                <img className="mx-auto" src={logo} alt="" />
              </Link>
              <h1 className="fs-32 mt-3">Sign In</h1>
            </div>

            <div className="p-2">
              <Form
                // className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Form.Group className="position-relative">
                    <Form.Label className="form-label">Email</Form.Label>
                    <Form.Control
                      className="form-ctl "
                      name="email"
                      placeholder={"Enter Email Address"}
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      // <Form.Control.Feedback type="invalid">
                      //   {validation.errors.email}
                      // </Form.Control.Feedback>
                      <div className="text-danger error-msg">
                        {validation.errors.email}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>

                <div className="mb-3">
                  <div className="position-relative">
                    <Form.Group className="position-relative">
                      <Form.Label className="form-label">
                        {"Password"}
                      </Form.Label>
                      <Form.Control
                        className="form-ctl "
                        name="password"
                        type={passwordType}
                        value={validation.values.password || ""}
                        placeholder={"Enter Password"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password &&
                            validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {/* <img
                      onClick={toggleShowPassword}
                      className=" view view_password"
                      src={showPassword ? Show : Hide}
                      alt="hide"
                    /> */}
                      {passwordType == "password" ? (
                        <span>
                          <AiOutlineEye
                            onClick={() => setPasswordType("text")}
                            className="view_password"
                          />{" "}
                        </span>
                      ) : (
                        <span>
                          <AiOutlineEyeInvisible
                            onClick={() => setPasswordType("password")}
                            className="view_password"
                          />{" "}
                        </span>
                      )}
                      {validation.touched.password &&
                        validation.errors.password ? (
                        // <Form.Control.Feedback type="invalid">
                        //   {validation.errors.password}
                        // </Form.Control.Feedback>
                        <div className="text-danger error-msg">
                          {validation.errors.password}
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </div>

                <div className="mt-3 text-end">
                  <Link to="/forgotEmail" className="text-muted forgot-text">
                    <i className="mdi mdi-lock me-1" />
                    {"Forgot your password?"}
                  </Link>
                </div>

                <div className="mt-3 d-grid">
                  <Button
                    disabled={process ? true : false}
                    className="common-btn mt-4 mb-3 w-100"
                    variant="primary"
                    type="submit"
                  >
                    <strong>{process ? "Loading..." : "Sign In"}</strong>
                  </Button>
                </div>

                <p className="f-15 text-center">
                  Don’t have an account?
                  <Link to="/register" className="text-theme fw-600 ms-2">
                    <u>Sign Up</u>
                  </Link>
                </p>
              </Form>
            </div>

            {/* <Form action="">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <div className="input-inner position-relative">
                  <Form.Control
                    id="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="input-icon">
                    <FaRegEnvelope />
                  </span>
                </div>
              </Form.Group>

              <Form.Group className="mb-2" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <div className="pwd position-relative">
                  <span className="input-icon">
                    <BiLockOpen />
                  </span>
                  <Form.Control
                      name="pwd"
                      placeholder="*********"
                      type={isRevealPwd ? "text" : "password"}
                      value={pwd}
                      onChange={(e) => setPwd(e.target.value)}
                    />
                    <img
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />

                  <Form.Control
                    id="pwd"
                    placeholder="Enter password"
                    name="pwd"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </Form.Group>
              <a className="f-14 text-end d-block" href="#">
                Forgot Password?
              </a>

              <Button
                className="common-btn mt-4 mb-3"
                variant="primary"
                type="submit"
              >
                Login
              </Button>
              <p className="f-15 text-center">
                Don’t have an account?{" "}
                <a className="fw-600" href="#">
                  <u>Register</u>
                </a>
              </p>
            </Form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
