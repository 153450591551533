import React, { useState, useEffect } from "react";
import { getAllTvts } from "../services/NftServices";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";
import NoData from "../components/NoDataComp/NoData";
import Pagination from "@mui/material/Pagination";
import TvtCard from "../components/Tvt/TvtCard";
const debounceTime = 300;
let distinctUntilChanged = null;

const ViewAllTvts = () => {
  const navigate = useNavigate();
  const [tvtData, setTvtData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [filter, setFilter] = useState("");
  const fetchTvts = async (pageNumber = 1, pageSize = 12, search = "") => {
    try {
      setLoading(true);
      const response = await getAllTvts(pageNumber, pageSize, search);
      if (response.status === 200) {
        setLoading(false);
        // setTvtData(response.data?.filteredData);

        const allTvtData = response.data?.filteredData;
        const allTvtDataWithoutTest = response?.data?.filteredData?.filter(
          (tvt) => !tvt?.["name"]?.toLowerCase()?.includes("test")
        );
        setTvtData(
          // window.location.hostname.includes("localhost") ?
          allTvtData
          // : allTvtDataWithoutTest
        );
        setTotalData(response?.data?.totalCount);
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  const handleSearch = (val) => {
    const value = val.trim();
    setFilter(value);
    if (distinctUntilChanged) {
      clearTimeout(distinctUntilChanged);
      distinctUntilChanged = null;
    }
    distinctUntilChanged = setTimeout(() => {
      setFilter(value);
      fetchTvts(1, 12, value);
      distinctUntilChanged = null;
    }, debounceTime);
  };

  useEffect(() => {
    fetchTvts();
  }, [page]);

  return (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          className="back-btn fw-500 text-black py-4"
          onClick={() => navigate(-1)}
        >
          <i className="pe-2 align-middle">
            <FaCircleChevronLeft />
          </i>
          Back
        </Link>
        <div>
          <input
            type="search"
            placeholder="Search TVT"
            style={{
              borderRadius: "12px",
              border: "none",
              padding: "5px 20px",
              background: "#d3d3d3a1",
            }}
            value={filter}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard-centerd">
          {tvtData?.length > 0 ? (
            <div className="row g-0">
              <div className="row mb-4">
                {tvtData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4"
                    >
                      <TvtCard
                        data={item}
                        fetchData={fetchTvts}
                        setLoading={setLoading}
                      />
                    </div>
                  );
                })}
              </div>
              {totalData > 12 && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    // minWidth: "200%",
                  }}
                >
                  <Pagination
                    color="primary"
                    count={Math.ceil(totalData / 12)}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                  // activePage={page}
                  />
                </div>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </div>
  );
};

export default ViewAllTvts;
