import React, { useState, useEffect } from "react";
import {
  getAllPlatformTvts,
  getAllTvtsForClients,
} from "../services/NftServices";
import { Link } from "react-router-dom";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { BiChevronRight } from "react-icons/bi";
import SellNftCard from "../components/Nft/SellNftCard";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";
import { ethers } from "ethers";
import NoData from "../components/NoDataComp/NoData";
import Pagination from "@mui/material/Pagination";
import TvtAbi from "../Abis/TvtAbi.json";
import SelltvtCard from "../components/Tvt/SellTvtCard";
import { useContract, useAddress, useSigner } from "@thirdweb-dev/react";

const debounceTime = 300;
let distinctUntilChanged = null;

const ViewAllSellTvts = () => {
  const myAddress = useAddress();
  const signer = useSigner();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [userMarketplaceTvts, setUserMarketplaceTvts] = useState([]);

  const [totalData, setTotalData] = useState("");
  const fetchUserMarketplaceTvts = async () => {
    try {
      setLoading(true);
      const response = await getAllTvtsForClients();

      if (response.status === 200) {
        const allTvtData = response?.data?.items;
        let result = [];
        for (let index = 0; index < allTvtData.length; index++) {
          const element = allTvtData[index];
          let res = await getMarketplaceTvts(element?.contractAddress);
          if (res) {
            if (res.length > 0) {
              for (let index = 0; index < res.length; index++) {
                const tvtDetail = res[index];
                if (tvtDetail?.tvtCount != 0 && element?.isNewTvt) {
                  result.push({
                    ...element,
                    balance: tvtDetail?.tvtPrice.toString(),
                    quantity: tvtDetail?.tvtCount.toString(),
                    address: tvtDetail?.userAddress,
                  });
                }
              }
            }
          }
        }

        setUserMarketplaceTvts(result); 
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  const getMarketplaceTvts = async (data) => {
    // console.log(data, "data>>>>>>>>>>>>>>>");
    // const _provider = new ethers.providers.Web3Provider(window.ethereum);
    // const signer = await _provider.getSigner();
    // const mywalletAddress = await window.ethereum.send("eth_requestAccounts");
    const nftContract = new ethers.Contract(data, TvtAbi, signer);
    // console.log(nftContract, "onpress called sell in marketplace>", signer);
    let respones = await nftContract?.getAllUserData();
    // console.log(respones, "testing 123456", data);
    return respones;
  };

  useEffect(() => {
    fetchUserMarketplaceTvts();
  }, [page]);

  return (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          className="back-btn fw-500 text-black py-4"
          onClick={() => navigate(-1)}
        >
          <i className="pe-2 align-middle">
            <FaCircleChevronLeft />
          </i>
          Back
        </Link>
        {/* <div>
          <input
            type="search"
            placeholder="Search NFT"
            style={{
              borderRadius: "12px",
              border: "none",
              padding: "5px 20px",
              background: "#d3d3d3a1",
            }}
            value={filter}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div> */}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard-centerd">
          {userMarketplaceTvts?.length > 0 ? (
            <div className="row g-0">
              <div className="row mb-4">
                {userMarketplaceTvts?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-3 col-md-6 col-12 mb-4"
                    >
                      <SelltvtCard
                        data={item}
                        fetchData={fetchUserMarketplaceTvts}
                        setLoading={setLoading}
                      />
                    </div>
                  );
                })}
              </div>
              {/* {totalData > 12 && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    // minWidth: "200%",
                  }}
                >
                  <Pagination
                    color="primary"
                    count={Math.ceil(totalData / 12)}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                    // activePage={page}
                  />
                </div>
              )} */}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </div>
  );
};

export default ViewAllSellTvts;
