import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { IMAGE_URL } from "../../helpers/api_helper";
import "./SuperFredDetail.css";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { ethers } from "ethers";
import RedAbi from "../../Abis/RedAbi.json";
import SfredAbi from "../../Abis/SfredAbi.json";
import { FaCalendar, FaClock } from "react-icons/fa";
import { ConversionContext } from "../../Context/context";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import WaitingLoader from "../loader/waitingLoader";
import { Link } from "react-router-dom";
import PinConfirm from "../Modals/PinConfirm";
import Loader from "../loader/loader";
import {
  fireToast,
  kycErrorPopUp,
  maintenancemodePopup,
} from "../../common/Toster";
import {
  transferNFT,
  sellNftOnUserMarketplace,
  getUserDataWithWallet,
  buyUserToUserSFred,
} from "../../services/NftServices";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useContract, useAddress, useSigner } from "@thirdweb-dev/react";
import configURl from "../../runtime.config";
import { getConversionRate } from "../../services/propertyServices";

function capitalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}
const SellSFredDetail = () => {
  const [showPinModal, setShowPinModal] = useState(false);
  const [formData, setFormData] = useState({});
  const { contract, isLoading, error } = useContract(configURl.redNewAddress);
  const signer = useSigner();
  const myAddress = useAddress();
  const navigate = useNavigate();
  const [openBuy, setOpenBuy] = useState(false);
  const [openRevoke, setOpenRevoke] = useState(false);
  const [mywalletAddress, setMyWalletAddress] = useState("");
  const [address, setAddress] = useState("");
  const [waitLoader, setWaitLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminAddress, setAdminAddress] = useState(0);
  const contextData = useContext(ConversionContext);
  const userDataContext = contextData?.userData;
  const location = useLocation();
  const data = location?.state?.data;
  const UserId = location?.state?.UserId;
  // console.log(location, "locations");
  // console.log(data, "UserId");
  const conversionRate = contextData?.conversionRate;
  const isPinActive = contextData?.userData?.isMpinActive;
  const handleBuyClose = () => setOpenBuy(false);
  const handleRevokeClose = () => setOpenRevoke(false);
  const [totalRedAmount, setTotalRedAmount] = useState("");
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  // const [adminPrivateKey, setAdminPrivateKey] = useState("");
  const BuySchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .notOneOf([0], "Quantity must be greater than 0")
      .min(0, "Quantity must be greater than or equal to 0")
      .max(
        data?.quantity / 10 ** 18,
        `Only ${data?.quantity / 10 ** 18} Super Freds are available`
      ),
  });
  const RevokeSchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .notOneOf([0], "Quantity must be greater than 0")
      .min(0, "Quantity must be greater than or equal to 0")
      .max(
        data?.quantity / 10 ** 18,
        `Only ${data?.quantity / 10 ** 18} Super Freds are available`
      ),
  });
  const [userWalletData, setUserWallerData] = useState("");
  useEffect(() => {
    getUserDataWithWallet(data?.address)
      .then((res) => {
        console.log(res?.data, "user data with wallet address");
        setUserWallerData(res?.data);
      })
      .catch((err) => {
        console.log(err, "errrr of wallet address ");
      });
  }, []);

  const buy = async () => {
    const exChangeRate = await getConversionRate();
    const { conversionrate, receivewalletaddress } = exChangeRate.data;
    setAdminAddress(receivewalletaddress);
    // setAdminPrivateKey(privatekey);
    setOpenBuy(true);
  };

  const revoke = async (data) => {
    // console.log(data, "revoke");
    const exChangeRate = await getConversionRate();
    const { conversionrate, receivewalletaddress } = exChangeRate.data;
    setAdminAddress(receivewalletaddress);
    // setAdminPrivateKey(privatekey);
    setOpenRevoke(true);
  };
  const onRevoke = async (values) => {
    try {
      setWaitLoader(true);
      setLoading(true);
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      const sfredContract = new ethers.Contract(
        data?.contractAddress,
        SfredAbi,
        signer
      );
      console.log(sfredContract, "sfredContract");
      const decimals = await sfredContract.decimals();
      const newAmount = ethers.utils.parseUnits(values?.quantity, decimals);
      await sfredContract
        .releaseSFred(newAmount, {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        })
        .then(async (response) => {
          setWaitLoader(false);
          setLoading(false);
          console.log(response, "responsesfred");
          navigate("/marketplace");
          fireToast("success", "Success. It will take some time. Please Wait");
        });
    } catch (error) {
      console.error("Error fetching super fred data:", error);
      setWaitLoader(false);
      setLoading(false);
      fireToast("error", "Something went wrong please try again later !");
    }
  };
  const isKycVerified =
    localStorage.getItem("isKycCompleted") === "true" ? true : false;

  // const getAddress = async () => {
  //   const accounts = await window.ethereum.request({
  //     method: "eth_requestAccounts",
  //   });
  //   setMyWalletAddress(accounts?.[0] ? accounts?.[0] : accounts?.result?.[0]);
  //   console.log(
  //     accounts?.[0] ? accounts?.[0] : accounts?.result?.[0],
  //     "adddddddddddddddd"
  //   );
  // };

  // useEffect(() => {
  //   // getAddress();
  // }, []);

  const onBuy = (values) => {
    (isPinActive
      ? () => {
        setOpenBuy(false);
        setFormData(values);
        setShowPinModal(true);
      }
      : () => {
        onBuyFunction(values);
      })();
  };

  const onBuyFunction = async (values) => {
    try {
      setWaitLoader(true);
      setLoading(true);
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      let amount = `${values?.quantity * data?.balance}`;
      let balance = await contract.erc20.balanceOf(myAddress);
      if (+balance.displayValue < +amount) {
        fireToast("error", "Transfer amount exceeds balance !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      const redContract = new ethers.Contract(
        configURl?.redNewAddress,
        contract.abi,
        signer
      );
      let nonce = await signer.getTransactionCount();
      const redDecimals = await redContract.decimals();
      const approvedAmount = await redContract.allowance(
        myAddress,
        data?.contractAddress
      );
      let formattedApprovedAmount = ethers.utils.formatUnits(
        approvedAmount,
        redDecimals
      );
      console.log(formattedApprovedAmount, "formattedApprovedAmount");
      console.log(nonce, "nonce");
      if (+formattedApprovedAmount < +amount) {
        console.log("ask for approve");
        nonce++;
        console.log(nonce, "nonce");
        const approveTrnx = await redContract.approve(
          data?.contractAddress,
          "1000000000000000000000000",
          {
            maxFeePerGas: gasPrice,
            maxPriorityFeePerGas: gasPrice,
            // nonce: nonce,
            gasLimit: 600000,
          }
        );
        console.log(approveTrnx, "approving...");
        const approved = await approveTrnx.wait();
        console.log(approved, "approval done");
      }
      nonce++;
      console.log(nonce, "nonce");
      console.log(data?.contractAddress, "data?.contractAddress");
      const contractInstance = new ethers.Contract(
        data?.contractAddress,
        SfredAbi,
        signer
      );
      const decimals = await contractInstance.decimals();
      const bigIntBalance = await contractInstance.balanceOf(myAddress);
      const sfredBalance = ethers.utils.formatUnits(bigIntBalance, decimals);
      const total = +sfredBalance + (+values?.quantity);
      if (total > +data?.SfredHoldLimit) {
        fireToast("error", "Transfer amount exceeds max limit per user !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      const hexQuantity = ethers.utils.parseUnits(
        values?.quantity?.toString(),
        decimals
      );
      const response = await contractInstance.spendLockedSFred(
        data?.address,
        hexQuantity,
        {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          // nonce: nonce,
          gasLimit: 600000,
        }
      );
      // console.log(data?.address, "22222222>>>", amount);
      // const tx = await contract.erc20.transfer(data?.address, amount);
      // console.log(tx, "tx22222222>>>");

      // const response = tx.receipt;
      console.log(response, "spendLockedSFred");

      // if (response.status) {
      //   console.log(response.status, ">>>>>>>>");
      // console.log(response, ">>>>>>>>");
      // console.log(values, "vals");

      setWaitLoader(true);
      setLoading(true);
      await buyUserToUserSFred({
        contractAddress: data?.contractAddress,
        sfredTransactionHash: response?.hash,
        walletAddressFrom: data?.address,
        walletAddressTo: myAddress,
        amount: data?.balance,
        quantity: values?.quantity,
        sfredId: data?._id,
      })
        .then(async (res) => {
          setWaitLoader(false);
          setLoading(false);
          fireToast(
            "success",
            "SFRED buy successfully. It will take some time. Please Wait"
          );
          navigate("/marketplace");
        })
        .catch((errApi) => {
          setWaitLoader(false);
          setLoading(false);
          fireToast("error", errApi);
        });
      // }
    } catch (error) {
      setWaitLoader(false);
      setLoading(false);
      console.log(error);
      fireToast("error", "Something went wrong please try again later !");
    }
  };

  return waitLoader ? (
    <WaitingLoader />
  ) : loading ? (
    <Loader />
  ) : (
    <div className="nftDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-6 sideImage">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.image})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.Image} /> */}
                </figure>
              </div>
              <div className="col-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.name}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.tradePrice} RED{" "}
                    {`($${data?.tradePrice * conversionRate})`}
                  </h6>
                  <h6 className="fw-bold">
                    Max User Limit : {data?.SfredHoldLimit}
                  </h6>
                </div>
                <div className="descText">
                  <h6 style={{ color: "#000" }}>Description</h6>
                  <p>{data?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 collectionDetails">
                <h6 className="fw-bold" style={{ color: "#000" }}>
                  Collection Details
                </h6>
                <div className="row mb-3">
                  {/* <div className="col-6 mb-2">
                    <FaCalendar /> Published by{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {data?.publishedBy}
                    </span>{" "}
                  </div> */}
                  <div className="col-6 mb-2">
                    <FaClock /> Published{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {momentTimeZone
                        .utc(data?.updatedAt)
                        .tz("America/Los_Angeles")
                        .format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="col-6 mb-2">
                    <FaCalendar /> Available From{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {momentTimeZone
                        .utc(data?.updatedAt)
                        .tz("America/Los_Angeles")
                        .fromNow()}
                    </span>
                  </div>
                </div>
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    Buy now to get your SFRED
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    Price per SFRED: {data?.balance} RED
                  </h6>
                  <h6 className="mb-3">
                    Listed Date : {momentTimeZone
                      .utc(data?.updatedAt)
                      .tz("America/Los_Angeles")
                      .format("DD-MM-YYYY")}{" "}
                    {momentTimeZone
                      .utc(data?.updatedAt)
                      .tz("America/Los_Angeles")
                      .format("h:mm a")}
                  </h6>
                  <h6 className="mb-3">
                    Listed QTY : {data?.quantity / 10 ** 18}{" "}
                  </h6>
                  {userWalletData?.name && (
                    <h6 className="mb-3">
                      Listed By : {userWalletData?.name}{" "}
                      {userWalletData?.lastname}
                    </h6>
                  )}

                  {data?.address?.toLowerCase() != myAddress?.toLowerCase() ? (
                    <div className="mintButton">
                      <button
                        className="btn"
                        onClick={() => {
                          if (userDataContext?.maintenanceMode) {
                            maintenancemodePopup();
                          }
                          // else if (isKycVerified) {
                          //   buy(data);
                          // } 
                          else {
                            buy(data);
                            // kycErrorPopUp();
                          }
                        }}
                      >
                        Buy Now
                      </button>
                    </div>
                  ) : (
                    <div className="mintButton">
                      <button className="btn" onClick={() => revoke(data)}>
                        Revoke
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openBuy}
        onHide={handleBuyClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Buy Super FRED
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleBuyClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                quantity: "",
              }}
              validationSchema={BuySchema}
              onSubmit={onBuy}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Quantity:
                          </label>
                          <Field
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("quantity", e.target.value);
                              setTotalRedAmount(e.target.value * data?.balance);
                            }}
                          />
                          {errors.quantity && touched.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Price (In RED):
                          </label>
                          <Field
                            value={totalRedAmount}
                            name="priceinred"
                            id="priceinred"
                            placeholder="Enter Price in RED"
                            className="form-control"
                            disabled
                          />
                          {errors.priceinred && touched.priceinred ? (
                            <div className="input-error">
                              {errors.priceinred}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openRevoke}
        onHide={handleRevokeClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Revoke Super FRED
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleRevokeClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                quantity: "",
              }}
              validationSchema={RevokeSchema}
              onSubmit={onRevoke}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Quantity:
                          </label>
                          <Field
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("quantity", e.target.value);
                            }}
                          />
                          {errors.quantity && touched.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Revoke
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setOpenBuy}
        formData={formData}
        showPinModal={showPinModal}
        handleSubmit={onBuyFunction}
        handleClosePinModal={handleClosePinModal}
      />
    </div>
  );
};

export default SellSFredDetail;
